<template>
  <div class="mt-3">
    <div class="h5 ml-3">Einstellungen {{ user.name }}</div>
    <div class="card m-3">
      <div class="card-header">Passwort ändern</div>
      <validation-observer tag="form"
                           v-slot="{invalid}"
                           ref="formStatus"
                           @submit.prevent="submit"
                           @keydown.enter.prevent>
        <div class="card-body">
          <div class="form-group">
            <validation-provider vid="Password" name="Passwort" rules="required" v-slot="{errors}">
              <label for="currentPassword">aktuelles Passwort</label>
              <input v-model="password"
                     id="currentPassword"
                     type="password"
                     class="form-control"
                     autocomplete="current-password"
                     ref="currentPasswordInout"
                     :class="{'is-invalid': errors.length > 0}"
                     autofocus/>
              <form-errors :errors="errors"/>
            </validation-provider>
          </div>
          <div class="form-group">
            <validation-provider vid="NewPassword" name="neues Passwort"
                                 rules="required" v-slot="{errors}">
              <label for="newPassword">neues Passwort</label>
              <input v-model="newPassword"
                     id="newPassword"
                     autocomplete="new-password"
                     type="password"
                     class="form-text form-control"
                     :class="{'is-invalid': errors.length > 0}"/>
              <form-errors :errors="errors"/>
            </validation-provider>
          </div>
          <div class="form-group">
            <validation-provider vid="passwordRepeat" name="Passwort-Wiederholung"
                                 mode="lazy"
                                 rules="required|password:@NewPassword" v-slot="{errors}">
              <label for="newPasswordRepeat">neues Passwort wiederholen</label>
              <input v-model="newPasswordRepeat"
                     id="newPasswordRepeat"
                     type="password"
                     class="form-text form-control"
                     :class="{'is-invalid': errors.length > 0}"/>
              <form-errors :errors="errors"/>
            </validation-provider>
          </div>
          <div class="form-group">
            <button
                type="submit"
                class="btn btn-primary"
                :disabled="invalid"
                @keydown.enter="submit">
              Passwort ändern
            </button>
            <button class="btn btn-secondary ml-2"
                    tabindex="-1"
                    @click="$router.push('/')">
              Abbrechen
            </button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import authenticationService from "@/services/authentication.service";
import {mapActions, mapGetters} from 'vuex'
import handleFormError from "@/utils/form-error-converter";
import FormErrors from "@/components/common/FormErrors";

export default {
  components: {FormErrors},
  data() {
    return {
      password: null,
      newPassword: null,
      newPasswordRepeat: null
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['showMessage', 'handleError']),
    submit() {
      authenticationService.changePassword(this.password, this.newPassword)
          .then(() => {
            this.showMessage({
              message: 'Ihr Passwort wurde geändert. Bitte melden Sie sich mit dem neuen Passwort an.',
              type: 'info'
            })
            authenticationService.logout()
          })
          .catch(error => {
            if (!handleFormError(error, this.$refs.formStatus))
              this.handleError(error)
          })
    }
  }
}
</script>